define("discourse/plugins/discourse-babypips-theme/discourse/components/premium-only", ["exports", "@ember/component", "@ember/service", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _service, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldShow}}
  {{yield}}
  {{/if}}
  */
  {
    "id": "VFxinUki",
    "block": "[[[41,[30,0,[\"shouldShow\"]],[[[18,1,null],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "discourse/plugins/discourse-babypips-theme/discourse/components/premium-only.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    router: (0, _service.inject)(),
    tagName: "",
    shouldShow(isPremium) {
      return isPremium === "true" || isPremium === true;
    }
  }, [["method", "shouldShow", [(0, _decorators.default)("currentUser.custom_fields.premium_user")]]])));
});