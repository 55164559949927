define("discourse/plugins/discourse-babypips-theme/discourse/services/adhesion-ad", ["exports", "@glimmer/tracking", "@ember/service", "discourse/plugins/discourse-babypips-theme/discourse/lib/settings"], function (_exports, _tracking, _service, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function trackEvent(args) {
    if (typeof window.bpTrackEvent !== "undefined") {
      window.bpTrackEvent(args);
    }
  }
  function toAnalyticsCategory(slug) {
    return `${slug}`.toLowerCase().trim().replace(/\W+/g, "").replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase());
  }
  const setSessionItem = (key, value) => typeof window !== "undefined" && window.sessionStorage?.setItem(key, value);
  const getSessionItem = key => typeof window !== "undefined" && window.sessionStorage?.getItem(key) === "true";
  const key = slug => slug ? `stickybar-${slug}` : undefined;
  class AdhesionAd extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "closed", [_tracking.tracked], function () {
      return false;
    }))();
    #closed = (() => (dt7948.i(this, "closed"), void 0))();
    cachedAd = (() => undefined)();
    get isPremiumUser() {
      if (!this.currentUser || !this.currentUser.custom_fields) {
        return false;
      }
      const premium = this.currentUser.custom_fields.premium_user;
      return premium === "true" || premium === true;
    }
    get campaignKey() {
      return key(this.cachedAd?.slug);
    }
    get campaignTitle() {
      return toAnalyticsCategory(this.cachedAd?.slug);
    }
    async getAd() {
      if (this.isPremiumUser) {
        return null;
      }
      if (this.cachedAd !== undefined) {
        return this.cachedAd;
      }
      const ad = await this._fetch();
      this.cachedAd = ad;
      if (!ad) {
        return;
      }
      if (getSessionItem(this.campaignKey)) {
        this.closed = true;
      } else {
        setSessionItem(this.campaignKey, false);
        this.closed = false;
      }
      return this.cachedAd;
    }
    close() {
      if (!this.cachedAd || !this.cachedAd.slug || this.closed) {
        return;
      }
      this.closed = true;
      setSessionItem(this.campaignKey, true);
      trackEvent({
        category: this.campaignTitle,
        action: "Close"
      });
    }
    impression() {
      if (!this.cachedAd || !this.cachedAd.slug || this.closed || this.isPremiumUser) {
        return;
      }
      trackEvent({
        category: this.campaignTitle,
        action: "Impression"
      });
    }
    click(e) {
      if (!this.cachedAd || !this.cachedAd.slug || this.closed || this.isPremiumUser) {
        return;
      }
      const text = `${e.target.textContent}`.trim();
      trackEvent({
        category: this.campaignTitle,
        action: "Click",
        payload: {
          text
        }
      });
    }
    async _fetch() {
      let ad;
      try {
        const resp = await fetch((0, _settings.mainSiteURLFor)("/na/a"), {
          headers: {
            "Content-Type": "application/json"
          }
        });
        const body = await resp.json();
        ad = body?.ad;
      } catch (ex) {
        console.error("Failed to load adhesion ad", ex); // eslint-disable-line no-console
      }
      return ad;
    }
  }
  _exports.default = AdhesionAd;
});