define("discourse/plugins/discourse-babypips-theme/discourse/components/adhesion-ad", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/plugins/discourse-babypips-theme/discourse/components/stickybar", "discourse/plugins/discourse-babypips-theme/discourse/lib/tracked-async-data", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _service, _stickybar, _trackedAsyncData, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdhesionAd extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "adhesionAd", [_service.service]))();
    #adhesionAd = (() => (dt7948.i(this, "adhesionAd"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get ad() {
      const ad = this.adhesionAd.getAd();
      return new _trackedAsyncData.default(ad);
    }
    static #_3 = (() => dt7948.n(this.prototype, "ad", [_tracking.cached]))();
    get shouldShowStickybar() {
      return this.ad.value !== undefined && this.ad.value !== null && !this.adhesionAd.closed;
    }
    get isThirdParty() {
      return this.ad.value !== undefined && this.ad.value !== null && this.ad.value.thirdParty;
    }
    renderThirdParty() {
      window.freestar = window.freestar || {
        queue: []
      };
      window.freestar.queue.push(function () {
        window.freestar.newStickyFooter("babypips_sticky_footer");
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "renderThirdParty", [_object.action]))();
    onImpression() {
      this.adhesionAd.impression(this.router.currentURL);
    }
    static #_5 = (() => dt7948.n(this.prototype, "onImpression", [_object.action]))();
    onClose(e) {
      this.adhesionAd.close(e);
    }
    static #_6 = (() => dt7948.n(this.prototype, "onClose", [_object.action]))();
    onClick(e) {
      this.adhesionAd.click(e);
    }
    static #_7 = (() => dt7948.n(this.prototype, "onClick", [_object.action]))();
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.ad.isResolved}}
          {{#if this.shouldShowStickybar}}
            {{#if this.isThirdParty}}
              <div id="stickybar" data-campaign={{this.ad.value.slug}} data-third-party />
              {{(this.renderThirdParty)}}
            {{else}}
              <Stickybar
                @ad={{this.ad.value}}
                @onClose={{this.onClose}}
                @onClick={{this.onClick}}
              />
            {{/if}}
            {{(this.onImpression)}}
          {{/if}}
        {{/if}}
      
    */
    {
      "id": "8PFcCr9c",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"ad\",\"isResolved\"]],[[[41,[30,0,[\"shouldShowStickybar\"]],[[[41,[30,0,[\"isThirdParty\"]],[[[1,\"          \"],[10,0],[14,1,\"stickybar\"],[15,\"data-campaign\",[30,0,[\"ad\",\"value\",\"slug\"]]],[14,\"data-third-party\",\"\"],[12],[13],[1,\"\\n          \"],[1,[28,[30,0,[\"renderThirdParty\"]],null,null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,0],null,[[\"@ad\",\"@onClose\",\"@onClick\"],[[30,0,[\"ad\",\"value\"]],[30,0,[\"onClose\"]],[30,0,[\"onClick\"]]]],null],[1,\"\\n\"]],[]]],[1,\"        \"],[1,[28,[30,0,[\"onImpression\"]],null,null]],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-babypips-theme/discourse/components/adhesion-ad.js",
      "scope": () => [_stickybar.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AdhesionAd;
});