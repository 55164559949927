define("discourse/plugins/discourse-babypips-theme/discourse/lib/tracked-async-data", ["exports", "@glimmer/tracking", "@ember/debug", "@ember/object/compat", "@ember/test-waiters"], function (_exports, _tracking, _debug, _compat, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Source: https://github.com/tracked-tools/ember-async-data/blob/main/ember-async-data/src/tracked-async-data.ts
  // Converted to JavaScript using: https://transform.tools/typescript-to-javascript

  const waiter = (0, _testWaiters.buildWaiter)("ember-async-data");

  // We only need a single instance of the pending state in our system, since it
  // is otherwise unparameterized (unlike the resolved and rejected states).
  const PENDING = ["PENDING"];

  // This class exists so that the state can be *wholly* private to outside
  // consumers, but its tracked internals can be both read and written directly by
  // `TrackedAsyncData` itself. The initial state of every `TrackedAsyncData` is
  // `PENDING`, though it may immediately become resolved for some `Promise`
  // instances (e.g. with a `Promise.resolve`).
  class State {
    static #_ = (() => dt7948.g(this.prototype, "data", [_tracking.tracked], function () {
      return PENDING;
    }))();
    #data = (() => (dt7948.i(this, "data"), void 0))();
  }

  // NOTE: this class is the implementation behind the types; the public types
  // layer on additional safety. See below! Additionally, the docs for the class
  // itself are applied to the export, not to the class, so that they will appear
  // when users refer to *that*.
  class _TrackedAsyncData {
    #token;

    /**
      The internal state management for the promise.
       - `readonly` so it cannot be mutated by the class itself after instantiation
      - uses true native privacy so it cannot even be read (and therefore *cannot*
        be depended upon) by consumers.
     */
    #state = (() => new State())();

    /**
      @param promise The promise to load.
     */
    constructor(data) {
      if (this.constructor !== _TrackedAsyncData) {
        throw new Error("tracked-async-data cannot be subclassed");
      }
      if (!isPromiseLike(data)) {
        this.#state.data = ["RESOLVED", data];
        return;
      }
      const promise = data;
      this.#token = waiter.beginAsync();

      // Otherwise, we know that haven't yet handled that promise anywhere in the
      // system, so we continue creating a new instance.
      promise.then(value => {
        this.#state.data = ["RESOLVED", value];
        waiter.endAsync(this.#token);
      }, error => {
        this.#state.data = ["REJECTED", error];
        waiter.endAsync(this.#token);
      });
    }

    /**
     * The resolution state of the promise.
     */
    get state() {
      return this.#state.data[0];
    }

    /**
      The value of the resolved promise.
       @note It is only valid to access `error` when `.isError` is true, that is,
        when `TrackedAsyncData.state` is `"ERROR"`.
      @warning You should not rely on this returning `T | null`! In a future
        breaking change which drops support for pre-Octane idioms, it will *only*
        return `T` and will *throw* if you access it when the state is wrong.
     */
    static #_ = (() => dt7948.n(this.prototype, "state", [_compat.dependentKeyCompat]))();
    get value() {
      (true && !(this.#state.data[0] === "RESOLVED") && (0, _debug.deprecate)("Accessing `value` when TrackedAsyncData is not in the resolved state is not supported and will throw an error in the future. Always check that `.state` is `'RESOLVED'` or that `.isResolved` is `true` before accessing this property.", this.#state.data[0] === "RESOLVED", {
        id: "tracked-async-data::invalid-value-access",
        for: "ember-async-data",
        since: {
          available: "1.0.0",
          enabled: "1.0.0"
        },
        until: "2.0.0"
      }));
      return this.#state.data[0] === "RESOLVED" ? this.#state.data[1] : null;
    }

    /**
      The error of the rejected promise.
       @note It is only valid to access `error` when `.isError` is true, that is,
        when `TrackedAsyncData.state` is `"ERROR"`.
      @warning You should not rely on this returning `null` when the state is not
        `"ERROR"`! In a future breaking change which drops support for pre-Octane
        idioms, it will *only* return `E` and will *throw* if you access it when
        the state is wrong.
     */
    static #_2 = (() => dt7948.n(this.prototype, "value", [_compat.dependentKeyCompat]))();
    get error() {
      (true && !(this.#state.data[0] === "REJECTED") && (0, _debug.deprecate)("Accessing `error` when TrackedAsyncData is not in the rejected state is not supported and will throw an error in the future. Always check that `.state` is `'REJECTED'` or that `.isRejected` is `true` before accessing this property.", this.#state.data[0] === "REJECTED", {
        id: "tracked-async-data::invalid-error-access",
        for: "ember-async-data",
        since: {
          available: "1.0.0",
          enabled: "1.0.0"
        },
        until: "2.0.0"
      }));
      return this.#state.data[0] === "REJECTED" ? this.#state.data[1] : null;
    }

    /**
      Is the state `"PENDING"`.
     */
    static #_3 = (() => dt7948.n(this.prototype, "error", [_compat.dependentKeyCompat]))();
    get isPending() {
      return this.state === "PENDING";
    }

    /** Is the state `"RESOLVED"`? */
    static #_4 = (() => dt7948.n(this.prototype, "isPending", [_compat.dependentKeyCompat]))();
    get isResolved() {
      return this.state === "RESOLVED";
    }

    /** Is the state `"REJECTED"`? */
    static #_5 = (() => dt7948.n(this.prototype, "isResolved", [_compat.dependentKeyCompat]))();
    get isRejected() {
      return this.state === "REJECTED";
    }

    // SAFETY: casts are safe because we uphold these invariants elsewhere in the
    // class. It would be great if we could guarantee them statically, but getters
    // do not return information about the state of the class well.
    static #_6 = (() => dt7948.n(this.prototype, "isRejected", [_compat.dependentKeyCompat]))();
    toJSON() {
      const {
        isPending,
        isResolved,
        isRejected
      } = this;
      if (isPending) {
        return {
          isPending,
          isResolved,
          isRejected
        };
      } else if (isResolved) {
        return {
          isPending,
          isResolved,
          value: this.value,
          isRejected
        };
      } else {
        return {
          isPending,
          isResolved,
          isRejected,
          error: this.error
        };
      }
    }
    toString() {
      return JSON.stringify(this.toJSON(), null, 2);
    }
  }
  const TrackedAsyncData = _TrackedAsyncData;
  var _default = _exports.default = TrackedAsyncData;
  /** Utility type to check whether the string `key` is a property on an object */
  function has(key, t) {
    return key in t;
  }
  function isPromiseLike(data) {
    return typeof data === "object" && data !== null && has("then", data) && typeof data.then === "function";
  }
});